import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Button, Image, Spin, Affix } from 'antd';
import DashboardService from '../services/DashboardService';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import DeviceDataService from "../services/DeviceDataService"


const { Paragraph, Title } = Typography;
const { Meta } = Card;

export default function DashboardView() {
    const [data, setData] = useState();
    const [filteredData, setFilteredData] = useState()
    const [ellipsis, setEllipsis] = useState(true);
    const [active, setActive] = useState("all")

    useEffect(() => {
        loadData();
    }, [])

    const downloadPdfFile = (fileName) => {
        if(fileName!==""||fileName!==null){
        DeviceDataService.downloadBrochure(fileName).then(res => {
            var blob = new Blob([res.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(blob)
            window.open(fileURL)
        })
    }
    }

    function loadData() {
        DashboardService.loadDeviceDetails().then(res => {
            const data = res.data;
            setData(data)
            setFilteredData(data)
        })
    }

    let category = [];
    if (data) {
        for (let i = 0; i < data.length; i++) {
            category.push(data[i].category);
        }
    }
    let uniqueCategory = [...new Set(category)];


    const onCategoryChange = (val) => {
        setActive(val)
        if (val === "all") {
            setFilteredData(data)
        } else {
            const filData = data.filter((el) => el.category === val)
            setFilteredData(filData)
        }
    }

    return (
        <div style={{margin:'-20px',marginBottom:'20px'}}>
              {filteredData ? <>
                <Affix offsetTop={64} >
                    <div style={{backgroundColor:'#A9CCE3',width:'100%'}}>
                        <div style={{justifyContent:'space-around', textAlign:'center',display:'flex', position:'relative',paddingTop:'3vh',paddingBottom:'3vh'}}>
                            <Button type={active === "all" ? "default" : "primary"} style={{fontWeight:'600',borderRadius:'5px'}}  onClick={()=>onCategoryChange("all")}>All Devices</Button>
                            {
                                uniqueCategory.map(el => {
                                    return(
                                        <Button type={active === el ? "default" : "primary"} style={{fontWeight:'600', borderRadius:'5px'}}  onClick={()=>onCategoryChange(el)}>{el}</Button>
                                    )
                                })
                            }

                        </div>
                    </div>
                </Affix>
                {
                    filteredData.map(el => {
                        return (
                            <Row>
                                <Col span={9} offset={1}>
                                 <Card
                                     className="card-bg"
                                     hoverable
                                     style={{ borderRadius: '1rem', marginTop: '5vh', textAlign: 'center' }}
                                     cover={
                                         <div style={{ borderRadius: '1rem 1rem 0 0', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                                             <Image
                                                 src={el.modelNameImage}
                                                 preview={false}
                                                 style={{ height: '60%', width: '60%' }}
                                             />
                                         </div>
                                     }
                                 >
                                     <Meta
                                         title={
                                             <div style={{ borderRadius: '1rem 1rem 0 0', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                 <Image
                                                     src={el.modelImage}
                                                     preview={false}
                                                     style={{ height: '60%', width: '60%' }}
                                                 />
                                             </div>
                                         }
                                         description={<Title level={5}>{el.category}</Title>}
                                     />
                                 </Card>

                                </Col>
                                <Col span={11} offset={2} style={{ paddingTop: '18vh' }}>
                                    <Col>

<Title
    level={1}
    style={{
        fontFamily: "inherit",
        fontWeight: "500",
        lineHeight: "1",
        marginBottom: "5vh",
    }}
    ellipsis={
        ellipsis
            ? {
                  rows: 2,
                  expandable: true,
                  symbol: "more",
              }
            : false
    }
>

     {el.title.split(/(𝘪³|𝘪)/g).map((part, index) =>
            part === "𝘪³" ? (
                <span key={index} style={{ fontFamily: "'Times New Roman', Times, serif", fontStyle: "italic" }}>
                    i<sup>3</sup>
                </span>
            ) : part === "𝘪" ? (
                <span key={index} style={{ fontFamily: "'Times New Roman', Times, serif", fontStyle: "italic" }}>
                    i
                </span>
            ) : (
                <span key={index}>{part}</span>
            )
        )}
</Title>





                                        <Paragraph
                                            style={{ fontWeight: '500', lineHeight: '1.2', letterSpacing: '1px', color: 'grey' }}
                                            ellipsis={
                                                ellipsis
                                                    ? {
                                                        rows: 4,
                                                        expandable: true,
                                                        symbol: 'more',
                                                    }
                                                    : false
                                            }
                                        >{el.description}</Paragraph>
                                    </Col>
                                    <Row style={{ marginTop: '40px' }}>
                                        {
                                            el.downloadBrochure ?
                                                <>
                                                    <Col span={8}>
                                                        <Link to={el.modelName + "/details"}><Button type="primary" style={{ height: '48px', borderRadius: '0.5rem', width: '100%' }}>Explore</Button></Link>
                                                    </Col>

                                                    <Col span={8} offset={2}>
                                                        <Button type='primary' onClick={() => downloadPdfFile(el.downloadBrochure)} style={{ height: '48px', borderRadius: '0.5rem', width: '100%' }}>Brochure</Button>
                                                    </Col></>
                                                :
                                                <Col span={8}>
                                                    <Link to={el.modelName + "/details"}><Button type="primary" style={{ height: '48px', borderRadius: '0.5rem', width: '100%' }}>Explore</Button></Link>
                                                </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        )
                    })
                } </> : <div style={{ textAlign: 'center', marginTop: '30vh' }}><Spin style={{color:'white'}} size="large" tip="Loading..." /></div>}
        </div>
    )
}


const Imagefun = (props) => {
    const [image, setImage] = useState();
    const [logoImage, setLogoImage] = useState();

    useEffect(() => {
    if(props.img){
        if(props.img!==""||props.img!==null||props.img !== undefined){
        DashboardService.downloadFile(props.img).then((response) => {
            const objectUrl = URL.createObjectURL(response.data);
            setImage(objectUrl);
            return objectUrl;
        }).catch((error)=>{
            console.log(error)
        })
    } }else{
        if(props.logoImg!==""||props.logoImg!==null||props.logoImg !== undefined){
        DashboardService.downloadFile(props.logoImg).then((response) => {
            const objectUrl = URL.createObjectURL(response.data);
            setLogoImage(objectUrl);
            return objectUrl;
        }).catch((error)=>{
            console.log(error)
        })
    }
}
}, [props.img,props.logoImg])
    return (
        <>
            {
                props.img ?
                <Image preview={false} src={image} style={{ borderRadius: '1rem 1rem 0 0', height: '60%', width: '60%' }} /> : <></>
            }
            {
                props.logoImg ?
                <Image preview={false} src={logoImage} style={{ borderRadius: '1rem 1rem 0 0', height: '60%', width: '60%', marginTop: '20px' }} /> : <></>
            }
        </>
    );
};


